// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-gatsby-mdx-mdx-wrappers-dir-c-2-a-424-a-7599-f-243-c-2-ed-11-ef-847-c-13042-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js": () => import("./gatsby-mdx/mdx-wrappers-dir/c2a424a7599f243c2ed11ef847c13042--scope-hash-3010b3badc54a9dfa4a4c80e419a41b2.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-c-2-a-424-a-7599-f-243-c-2-ed-11-ef-847-c-13042-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-services-jsx": () => import("./../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */)
}

